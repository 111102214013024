window.jQuery = require('jquery');
window.$ = window.jQuery;
var main = require('base_th/main');
var processInclude = require('base/util');

$(document).ready(function () {
    processInclude(require('int_tealium_sfra/tealiumEvent'));
});

module.exports = main;
